import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JWT_LOCALSTORAGE_KEY } from 'shared/const/localstorage';
import { UserSchema } from '../types/userSchema';
import { User } from '../types/User';

const initialState: UserSchema = {
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = undefined;
      localStorage.removeItem(JWT_LOCALSTORAGE_KEY);
    },
  },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
