import React, { ReactNode, useEffect } from 'react';
import { useGetCurrentUser, userActions } from 'entities/User';
import { Loader } from 'shared/ui/Loader';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();
  const { data: user, isLoading, error } = useGetCurrentUser();

  useEffect(() => {
    if (!user) return;
    dispatch(userActions.setUser(user));
  }, [dispatch, user]);

  if (isLoading) return (<Loader />);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<>{children}</>);
};
