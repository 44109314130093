import {
  BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { getToken, storeToken } from 'shared/api/token';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  // baseUrl: __API__,
  prepareHeaders: (headers) => {
    const token = getToken() || '';
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      try {
        // try to get a new token
        const refreshResult = await baseQuery({
          url: '/api/auth/refresh',
          method: 'POST',
          body: { clientId: 'sd' },
        }, api, extraOptions);

        if (refreshResult.data) {
          // @ts-ignore
          const { token } = refreshResult.data;
          storeToken(token);
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          // refresh failed - do something like redirect to login or show a "retry" button
          // api.dispatch(loggedOut());
        }
      } finally {
        mutex.release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const rtkApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
