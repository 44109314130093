import { rtkApi } from 'shared/api/rtkApi';
import { User } from '../types/User';

const currentUserApi = rtkApi
  .enhanceEndpoints({})
  .injectEndpoints({
    endpoints: (build) => ({
      getCurrentUser: build.query<User, void>({
        query: () => ({
          url: '/api/auth/currentUser',
        }),
      }),
    }),
  });

export const useGetCurrentUser = currentUserApi.useGetCurrentUserQuery;
